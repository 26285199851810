import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';
import { useLocation } from 'react-router-dom';

export const FaqContext = createContext();

export function FaqContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);

    const [addModal, setAddModal] = useState(false);
    const [listData, setListData] = useState([]);
    const [categoryDropData, setCategoryDropData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [locationState, setLocationState] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [formTemp, setFormTemp] = useState({});
    const location = useLocation();
    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({});
    };

    const onLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('courses/faq/list', { course_id: location.state?.id });
            if (response.data.s === 1) {
                setListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch listData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onCategoryDropApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('courses/faq-category/dropdown', { parent_id: 0 });
            if (response.data.s === 1) {
                setCategoryDropData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch DropData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        onCategoryDropApi();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (location.state) {
            setLocationState(location.state);
            onLoadApi();
        }
    }, [location.state]);
    const contextValue = {
        locationState,
        setLocationState,
        categoryDropData,
        setCategoryDropData,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        listData,
        setListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onLoadApi
    };

    return <FaqContext.Provider value={contextValue}>{children}</FaqContext.Provider>;
}
